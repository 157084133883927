import 'destyle.css'
import 'app/styles/global.scss'
import { getParser } from 'bowser'

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}

export const onClientEntry = () => {
  window.onload = () => {
    const navigator = getParser(window.navigator.userAgent)
    if (navigator.is('chrome') && window.location.hash) {
      setTimeout(() => {
        const hash = window.location.hash
        window.location.hash = ''
        window.location.hash = hash
      }, 300)
    }
  }
}
