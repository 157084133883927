module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-manifest-virtual-eef06af102/0/cache/gatsby-plugin-manifest-npm-2.10.0-7d7c41444a-8efe6f2c03.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Крепость","short_name":"Крепость","description":"Помощь жертвам домашнего насилия","start_url":"/","lang":"ru","background_color":"#e1e6eb","theme_color":"#e1e6eb","display":"standalone","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ae24a68de6f626f28a9cc482f4dd6111"},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-offline-virtual-b9be69d9bf/0/cache/gatsby-plugin-offline-npm-3.8.0-16c35b232b-31aa9bf3c9.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
